import { navbarOptions } from "@/stores/NavBarOptionsStore";

function loadPage(component) {
  return () =>
    import(/* webpackChunkName:"[request]" */ `@/pages/${component}.vue`);
}

export default [
  //{ path: '/', component: loadPage('Landing') },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: loadPage("Login"),
    meta: {
      requiresAuth: false,
      requiresUnauth: true,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/logout",
    component: loadPage("Logout"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/404",
    component: loadPage("NotFound"),
    meta: {
      requiresAuth: false,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/settings",
    component: loadPage("Settings"),
    meta: {
      requiresAuth: false,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/support",
    name: "feedback",
    component: loadPage("FeedbackSubmission"),
    meta: {
      requiresAuth: false,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/feedback",
    name: "feedback_submission",
    component: loadPage("FeedbackSubmission"),
    meta: {
      requiresAuth: false,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/adminDashboard",
    name: "AdminDashboard",
    component: loadPage("AdminDashboard"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ADMIN,
    },
  },
  {
    path: "/customersDashboard",
    component: loadPage("CustomersDashboard"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ADMIN,
    },
  },
  {
    path: "/adminCreateExercise",
    component: loadPage("AdminCreateExercise"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ADMIN,
    },
  },
  {
    path: "/privacy",
    component: loadPage("PrivacyPage"),
    meta: {
      requiresAuth: false,
      selectedNavbarItem: navbarOptions.NONE,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: loadPage("Dashboard"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.HOME,
    },
  },
  {
    path: "/register-athlete",
    component: loadPage("RegisterAthlete"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/register-patient",
    component: loadPage("RegisterAthlete"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/assessments",
    component: loadPage("AllAssessments"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ASSESSMENTS,
    },
  },
  {
    path: "/athletes",
    name: "athletes",
    component: loadPage("AllAthletes"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/patients",
    name: "patients",
    component: loadPage("AllAthletes"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/athlete",
    name: "athleteAssessments",
    component: loadPage("AthleteAssessments"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/patient",
    name: "patientAssessments",
    component: loadPage("AthleteAssessments"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/assessment",
    name: "assessment",
    component: loadPage("Assessment"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ASSESSMENTS,
    },
  },
  {
    path: "/athlete/edit",
    name: "athleteEdit",
    component: loadPage("EditAthlete"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/patient/edit",
    name: "patientEdit",
    component: loadPage("EditAthlete"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.ATHLETES,
    },
  },
  {
    path: "/groups",
    component: loadPage("AllGroups"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.GROUPS,
    },
  },
  {
    path: "/group",
    name: "group",
    component: loadPage("EditGroup"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.GROUPS,
    },
  },
  {
    path: "/exercises",
    name: "exercises",
    component: loadPage("AllExercises"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.EXERCISES,
    },
  },
  {
    path: "/editExercise",
    name: "editExercise",
    component: loadPage("EditExercise"),
    meta: {
      requiresAuth: true,
      selectedNavbarItem: navbarOptions.EXERCISES,
    },
  },
  //This route should always be the last on the list
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: loadPage("NotFound"),
  },
];
